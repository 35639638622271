import { computed, defineComponent, reactive, watch } from "vue";
import { useStore } from "@/store/store";

import { onBeforeRouteLeave, RouteLocationNormalized } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "LeavePreventPopup",
  props: { form: Object as () => Record<string, any>, disabled: Boolean },
  components: {},
  setup(props) {
    const store = useStore();
    const state = reactive({
      dialogVisible: false,
      toLink: null as RouteLocationNormalized | null,
      isFormDirty: false,
      redirected: false,
    });
    const canRedirect = computed(() => {
      return (
        store.state.auth.isForceLogOut ||
        state.redirected ||
        props.disabled ||
        !state.isFormDirty
      );
    });

    watch(props.form as any, (oldValue, newValue) => {
      state.isFormDirty = true;
    });

    window.addEventListener("beforeunload", (e) => {
      if (canRedirect.value) {
        return undefined;
      }

      e.preventDefault();
      const confirmationMessage =
        "It seems that you have unsaved changes. " +
        "Do you want to leave this page without saving them?";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });

    onBeforeRouteLeave((to, from, next) => {
      if (canRedirect.value) {
        return next();
      }
      state.toLink = to;
      state.dialogVisible = true;

      return next(false);
    });

    return {
      state,
      leave: () => {
        state.dialogVisible = false;
        state.redirected = true;
        if (state.toLink) {
          router.push(state.toLink.fullPath);
        }
      },
    };
  },
});
