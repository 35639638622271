import useFormUtils from "@/uses/useFormUtils";
import {
  SITE_NAME_REQUIRED_MESSAGE,
  SEX_REQUIRED_MESSAGE,
  YEAR_OF_BIRTH_REQUIRED_MESSAGE,
  YEAR_OF_BIRTH_RANGE_MESSAGE,
} from "./../../../utils/validationMessages";
import { SiteItemForFilter } from "../../../api/interfaces/SitesInterfaces";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";

import { SUBJECT_ID_REQUIRED_MESSAGE } from "../../../utils/validationMessages";
import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";

import DropdownFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import LeavePreventPopup from "@/components/LeavePreventPopup/LeavePreventPopup.vue";

import { useStore } from "@/store/store";

import { getSitesForFilter } from "@/api/SitesApi";
import { getSitesFilterItem } from "@/utils/filtersUtils";
import { SubjectProfile } from "@/api/interfaces/SubjectsInterfaces";
import { editSubject, createSubject } from "@/api/SubjectsApi";
import useDateFormatter from "@/uses/useDateFormatter";
import useUserRoles, { UserRole } from "@/uses/useUserRoles";
import { useRoute, useRouter } from "vue-router";
import { showError, showErrorOptional } from "@/utils/notificationUtils";

export default defineComponent({
  name: "SubjectForm",
  props: { editModel: { default: null, type: Object as () => SubjectProfile } },
  components: { DropdownFilter, LeavePreventPopup },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const useRoles = useUserRoles(store);

    const subjectForm = ref<any>(null);

    const isEdit = computed(() => props.editModel);

    const year = props.editModel?.year_of_birth
      ? new Date(props.editModel.year_of_birth, 1, 1)
      : "";

    const form = reactive({
      subject_id: "",
      site_id: useRoles.hasRole(UserRole.SiteAdmin, UserRole.Clinician)
        ? useRoles.getSiteId()
        : null,
      sex_type: "",
      comment: "",
      ...(props.editModel ? props.editModel : {}),
      year_of_birth: year,
    });

    const dateFormatter = useDateFormatter();
    const { startYear, endYear } = dateFormatter;

    const rules = reactive({
      subject_id: [
        {
          required: true,
          message: SUBJECT_ID_REQUIRED_MESSAGE,
          trigger: "blur",
        },
        {
          pattern: /^\S*$/,
          message: "Spaces are not allowed in SubjectId",
          trigger: "blur",
        },
      ],
      site_id: [
        {
          required: true,
          message: SITE_NAME_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],
      sex: [
        {
          required: true,
          message: SEX_REQUIRED_MESSAGE,
          trigger: "blur",
        },
      ],
      year_of_birth: [
        {
          required: true,
          message: YEAR_OF_BIRTH_REQUIRED_MESSAGE,
          trigger: "blur",
        },
        {
          message: YEAR_OF_BIRTH_RANGE_MESSAGE(startYear, endYear),
          trigger: "blur",
          validator: (rule: any, value: Date) => {
            const year = value.getFullYear();
            if (value) {
              return startYear <= year && year <= endYear;
            } else {
              return true;
            }
          },
        },
      ],
    });

    const state = reactive({
      isSubmitted: false,
      loading: false,
      sites: {
        items: [],
        values: isEdit.value ? form.site_id : store.getters.userSiteId,
      } as FilterObject,
    });

    watch(state.sites, (newValue: any, oldValue: any) => {
      if (form.site_id != newValue.values) {
        form.site_id = newValue.values;
        subjectForm.value.validateField('site_id');
      }
    });

    const sitesList = ref<SiteItemForFilter[]>([]);
    onBeforeMount(() => {
      if (useRoles.hasRole(UserRole.SystemAdmin)) {
        getSitesForFilter().then((sites) => {
          sitesList.value = sites.entities;
          state.sites.items = getSitesFilterItem(sites);
        });
      }
    });

    return {
      state,
      form,
      rules,
      isEdit,
      loading: false,
      ...useRoles,
      ...useFormUtils(form),
      ...dateFormatter,
      site: computed(() => {
        const value = state.sites.values as string;

        const site = sitesList.value.find((s) => s.id === value);

        return site?.name || "-";
      }),
      subjectForm,
      isRequiredFilled: computed(
        () =>
          !!(
            form.subject_id &&
            form.sex_type &&
            form.site_id &&
            form.year_of_birth
          )
      ),
      submitForm() {
        state.loading = true;
        subjectForm.value.validate(async (valid: boolean) => {
          try {
            if (valid) {
              const model = {
                ...form,
                year_of_birth: (form?.year_of_birth as Date).getFullYear(),
              };

              if (isEdit.value) {
                await editSubject(
                  model as SubjectProfile,
                  props.editModel.site_id
                );

                setTimeout(
                  () =>
                    router.push({
                      // name: store.state.meta.prevRouteName,
                      name: "View Subject",
                      params: {
                        id: (model as any).id || "",
                        siteId: model.site_id || "",
                      },
                    }),
                  100
                );
              } else {
                const id = await createSubject(
                  model as SubjectProfile,
                  model.site_id
                );

                setTimeout(
                  () =>
                    router.push({
                      // name: store.state.meta.prevRouteName,
                      name: "View Subject",
                      params: {
                        id: id,
                        siteId: model.site_id || "",
                      },
                    }),
                  100
                );
              }

              // state.loading = false;
              state.isSubmitted = true;
            } else {
              return false;
            }
          } catch (ex) {
            if (
              ex?.error?.response?.data?.title ===
              "Entity with such SubjectName and SiteId already exists."
            ) {
              showErrorOptional(
                "Subject with same Subject ID exists.",
                ex,
                "Error while submitting"
              );
            }
          } finally {
            state.loading = false;
          }
        });
      },
      cancel() {
        router.back();
      },
    };
  },
});
