import { defineComponent } from "vue";

import SubjectForm from "./../SubjectForm/SubjectForm.vue";

export default defineComponent({
  name: "AddSubject",
  props: {},
  components: { SubjectForm },
  setup() {
    return {};
  },
});
