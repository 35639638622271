import FileDownload from "js-file-download";

import { axiosInstance as axios } from "./interceptor";
// import axios from "axios";

import { getURLFunc } from "./apiUtils";
import { SearchRequestParams } from "@/interfaces/SearchRequestParams";

import { SearchResult } from "@/api/interfaces/SearchResult";
import {
  AssessmentItem,
  AssessmentSearchItem,
  AssessmentStatus,
  CodeStatus,
  HiPALSubjectItem,
  SubjectItem,
  SubjectProfile,
} from "./interfaces/SubjectsInterfaces";
import { LogicalOperator } from "./SitePresetsApi";
import useDateFormatter from "@/uses/useDateFormatter";

const getUrl = (siteId?: string) =>
  getURLFunc(`/study/api/${siteId ? `sites/${siteId}/subjects` : "subjects"}`);

export function viewSubjectProfile(subjectId: string, siteId?: string) {
  return axios
    .get<SubjectProfile>(getUrl(siteId)(`/${subjectId}`))
    .then((r) => r.data);
}

export function getSubjectsSearch(params: SearchRequestParams) {
  return axios
    .post<SearchResult<SubjectItem>>(getUrl()("/search"), params)
    .then((r) => r.data);
}

interface HiPALSearchRequestParams extends SearchRequestParams {
  score_mmse_from?: number;
  score_mmse_to?: number;
  operator_type?: LogicalOperator;
  score_adascog_from?: number;
  score_adascog_to?: number;
  age_from?: number;
  age_to?: number;
}

export function getHiPALSubjectsSearch(params: HiPALSearchRequestParams) {
  return axios
    .post<SearchResult<HiPALSubjectItem>>(
      getURLFunc(`/study/api`)("/assessmentscores/search"),
      params
    )
    .then((r) => r.data);
}

export function createSubject(subject: SubjectProfile, siteId?: string) {
  return axios.post<string>(getUrl(siteId)(""), subject).then((r) => r.data);
}

export function editSubject(subject: SubjectProfile, siteId?: string) {
  return axios
    .put<void>(getUrl(siteId)(`/${subject.id}`), subject)
    .then((r) => r.data);
}

export function deleteSubject(subjectId: string, siteId?: string) {
  return axios
    .delete<void>(getUrl(siteId)(`/${subjectId}`))
    .then((r) => r.data);
}

export function getLastAssessment(subjectId: string, siteId?: string) {
  return axios
    .get<{ code: string; status: CodeStatus }>(
      getUrl(siteId)(`/${subjectId}/assessment/invitation-code`)
    )
    .then((r) => r.data);
}

export function startNewAssesment(subjectId: string, siteId?: string) {
  return axios
    .post<{ code: string; status: CodeStatus }>(
      getUrl(siteId)(`/${subjectId}/assessment/invitation-code`)
    )
    .then((r) => r.data);
}

export function revokeAssesment(subjectId: string, siteId?: string) {
  return axios
    .post<{ code: string }>(
      getUrl(siteId)(`/${subjectId}/assessment/invitation-code/revoke`)
    )
    .then((r) => r.data);
}

export function getAssessmentsSearch(
  params: SearchRequestParams,
  subjectId: string,
  siteId: string
) {
  return axios
    .post<SearchResult<AssessmentSearchItem>>(
      getUrl(siteId)(`/${subjectId}/assessmentscores/search`),
      params
    )
    .then((r) => r.data);
}

export function getJSON(
  subjectIds: string[],
  filterParams: HiPALSearchRequestParams
) {
  const url = getURLFunc(`/study/api/assessmentscores/export`)("");

  const params = subjectIds.length ? { subject_ids: subjectIds } : filterParams;

  return axios
    .post<any>(url, params, { responseType: "blob" })
    .then((r) => {
      FileDownload(
        r.data,
        `HiPAL_SubjectData_${useDateFormatter().formatDate(
          new Date(),
          "yyyy-MM-dd"
        )}.json`
      );
    });
}
